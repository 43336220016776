import './App.css';
import { Container } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import TopLevelRoutes from './Route';
import background from "./images/background.png"

function App() {
  return (
    <div style={{ backgroundImage: `url(${background})`, height: '100%', width: '100%', position: 'fixed', overflowY: 'scroll'  }}>
      <Router>
        <Container maxWidth="xl" disableGutters>
          <TopLevelRoutes />
        </Container>
      </Router>
    </div>
  )
}

export default App;

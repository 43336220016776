import { Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ConfirmMessage from "./ConfirMessage/ConfirmMessage";
import Consent from "./Consent/Consent";
import { updateBankAccountRequestStatus } from "./Data/Api/bank-account-request-api";
import { EBankAccountRequestUpdateStatus, EFlinksEventSteps } from "./Data/constants";
import Flinks from "./Flinks";
import logo from "./images/logo.png"

export default function App(): JSX.Element {
    const { lang, tag } = useParams()
    const [useLanguage, setUseLanguage] = useState(lang)
    const [searchParams] = useSearchParams()
    const redirectUrl = searchParams.get('redirect')

    const [consentAccepted, setConsentAccepted] = useState(false)
    const [isInProgress, setIsInProgress] = useState(true)
    const [bankAccountRequestStatus, setbankAccountRequestStatus] = useState(0)


    const handleConsentAccepted = () => {
        setConsentAccepted(true)
        hanleStatusChange(EBankAccountRequestUpdateStatus.CONSENT_ACCEPTED)
    }

    const hanleStatusChange = (status: number) => {
        if (tag)
            updateBankAccountRequestStatus({ id: tag, status: status })
    }

    const hanleLanguageChange = () => {
        setUseLanguage(useLanguage === 'fr' ? 'en' : 'fr')
    }

    useEffect(() => {
        document.title = "iFinance";
        window.addEventListener('message', function (e) {
            if (e.data?.step) {
                if (e.data.step === EFlinksEventSteps.REDIRECT) {
                    setIsInProgress(false)
                    hanleStatusChange(EBankAccountRequestUpdateStatus.REQUEST_PROCESSED)
                }
            }
        });

        if (bankAccountRequestStatus < EBankAccountRequestUpdateStatus.LINK_ACCESS) {
            setbankAccountRequestStatus(EBankAccountRequestUpdateStatus.LINK_ACCESS)
            hanleStatusChange(EBankAccountRequestUpdateStatus.LINK_ACCESS)
        }
    }, [])

    const iframeUrl = process.env.REACT_APP_FLINKS_IFRAME + "?theme=light&desktopLayout=true&institutionFilterEnable=true&demo={0}&consentEnable=true&accountSelectorEnable=true&daysOfTransactions=Days365&monthsOfStatements=Months12&enhancedMFA=true&language={1}&tag={2}&scheduleRefresh=false"
    return (
        <>
            <Stack alignItems="center">
                <img src={logo} alt="" width="250px" style={{ margin: 20 }} />

                <Button variant="contained" color="primary" onClick={hanleLanguageChange} sx={{ m: 2 }}>{useLanguage === 'fr' ? 'En' : 'Fr'}</Button>
                {!consentAccepted && isInProgress &&
                    <Consent onClickAcceptConstent={handleConsentAccepted} language={useLanguage ?? ''} />}
                {consentAccepted && isInProgress &&
                    <Flinks
                        iframUrl={iframeUrl}
                        language={useLanguage ?? ''}
                        tag={tag ?? ''} />}
                {!isInProgress &&
                    <ConfirmMessage language={useLanguage ?? ''} redirectUrl={redirectUrl} />}
            </Stack>
        </>
    )
}
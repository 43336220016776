import { Done } from "@mui/icons-material"
import { Button, Card, Checkbox, FormControlLabel } from "@mui/material"
import { useState } from "react"
import ConsentEn from "./ConsentEn"
import ConsentFr from "./ConsentFr"

type Props = {
    language: string
    onClickAcceptConstent: () => void
}

function Consent({ language, onClickAcceptConstent }: Props) {
    const [checked, setChecked] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const labelConsent = language === 'fr' ? 'J\'ai lu et autorise iFinance Canada Inc. aux termes et conditions mentionnés' : 'I have read and authorize iFinance Canada Inc. under the terms and conditions mentioned'
    return (
        <>
            <Card sx={{ minWidth: 275, p: 2 }}>
                {language === 'fr' && <ConsentFr />}
                {language === 'en' && <ConsentEn />}
                <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} />} label={labelConsent} />
                <br />
                <Button
                    variant="contained"
                    color="success"
                    onClick={() => onClickAcceptConstent()}
                    startIcon={<Done />}
                    disabled={!checked}
                >
                    {language === 'fr' ? 'J\'ACCEPTE' : 'I ACCEPT'}
                </Button>
            </Card>
        </>
    )
}

export default Consent
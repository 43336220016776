import { Button, Card, Stack } from "@mui/material"
import { Done } from "@mui/icons-material"
import ConfirmMessageEn from "./ConfirmMessageEn"
import ConfirmMessageFr from "./ConfirmMessageFr"

type Props = {
    language: string
    redirectUrl: string | null
}

function ConfirmMessage({ language, redirectUrl }: Props) {
    return (
        <>
            <Card sx={{ minWidth: 275, p: 2, height: '100%' }}>
                <Stack alignItems="center">
                    {language === 'fr' && <ConfirmMessageFr />}
                    {language === 'en' && <ConfirmMessageEn />}

                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => redirectUrl !== null ? window.location.replace(redirectUrl) : window.close()}
                        startIcon={<Done />}
                    >
                        OK
                    </Button>
                </Stack>
            </Card>
        </>
    )
}

export default ConfirmMessage
import { CheckCircleOutline } from "@mui/icons-material"



function ConfirmMessageEn() {
    return (
        <>
            <h1>Thank you!</h1>
            <CheckCircleOutline color="success" sx={{ fontSize: 100 }} />
            <p>Your account has been retrieved successfully</p>
            <p>We are processing the informations of your login.</p>
        </>
    )
}

export default ConfirmMessageEn
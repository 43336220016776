import { CheckCircleOutline } from "@mui/icons-material"



function ConfirmMessageFr() {
    return (
        <>
            <h1>Merci!</h1>
            <CheckCircleOutline color="success" sx={{ fontSize: 100 }} />
            <p>Votre compte a été connecté avec succès</p>
            <p>Nous traitons les détails de votre connexion</p>
        </>
    )
}

export default ConfirmMessageFr
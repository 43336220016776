export enum EFlinksEventSteps {
    SESSION_STORAGE_BLOCKED = "SESSION_STORAGE_BLOCKED",
    SESSION_STORAGE_BLOCKED_RETRY = "SESSION_STORAGE_BLOCKED_RETRY",
    INSTITUTION_SELECTED = "INSTITUTION_SELECTED",
    COMPONENT_LOAD_CREDENTIAL = "COMPONENT_LOAD_CREDENTIAL",
    COMPONENT_ACCEPT_CONSENT = "COMPONENT_ACCEPT_CONSENT",
    COMPONENT_DENY_CONSENT = "COMPONENT_DENY_CONSENT",
    COMPONENT_ACCEPT_TERMS = "COMPONENT_ACCEPT_TERMS",
    COMPONENT_DENY_TERMS = "COMPONENT_DENY_TERMS",
    COMPONENT_CLICK_TERMS_URL = "COMPONENT_CLICK_TERMS_URL",
    SUBMIT_CREDENTIAL = "SUBMIT_CREDENTIAL",
    RETRY_COUNT = "RETRY_COUNT",
    MAXIMUM_RETRY_REACHED = "MAXIMUM_RETRY_REACHED",
    QUESTION_NOT_FOUND = "QUESTION_NOT_FOUND",
    COMPONENT_LOAD_MFA = "COMPONENT_LOAD_MFA",
    SUBMIT_MFA = "SUBMIT_MFA",
    SUBMIT_GET_MFA_QUESTIONS = "SUBMIT_GET_MFA_QUESTIONS",
    ENHANCED_MFA_HAS_QUESTIONS = "ENHANCED_MFA_HAS_QUESTIONS",
    ENHANCED_MFA_NO_QUESTIONS = "ENHANCED_MFA_NO_QUESTIONS",
    SUBMIT_ANSWER_MFA_QUESTIONS = "SUBMIT_ANSWER_MFA_QUESTIONS",
    ENHANCED_MFA_SUCCESS = "ENHANCED_MFA_SUCCESS",
    SKIP_ENHANCED_MFA = "SKIP_ENHANCED_MFA",
    COMPONENT_LOAD_ACCOUNT_SELECTION = "COMPONENT_LOAD_ACCOUNT_SELECTION",
    COMPONENT_LOAD_INSTITUTION_SELECTOR = "COMPONENT_LOAD_INSTITUTION_SELECTOR",
    ACCOUNT_SELECTED = "ACCOUNT_SELECTED",
    REDIRECT = "REDIRECT",
}

export enum EBankAccountRequestUpdateStatus {
    REQUEST_CREATED = 0,
    LINK_ACCESS = 1,
    CONSENT_ACCEPTED = 2,
    REQUEST_PROCESSED = 3,
    KYC_RECEIVED = 4,
    DOCUMENTS_RECEIVED = 5
}

function ConsentEn() {
    return (
        <>
            <h1 >Consent</h1>
            <p>
                1. I authorize iFinance Canada Inc., or any other service provider appointed by iFinance Canada Inc., to collect, verify and/or obtain any personal
                information concerning me, including, in particular, but without limitation, my bank statements as well as a void cheque, insofar as these are necessary
                for the analysis, evaluation or management of my file or allow, where appropriate, iFinance Canada Inc. to exercise its remedies and other planned means provided in
                the contract binding me to iFinance Canada Inc., if applicable, to any person or organization, especially, but not limited to, my employer, the owner of premises where I
                live in the event that I am a tenant as well as any credit reporting agency, insurer, mortgage lender, charter bank, credit union, lender and/or any person with whom I
                have or have had a financial relationship, to use, transmit, store and/or to communicate them in order to, especially, but not limited to, confirm my identity
                and my job, contact me, assess my financial and professional situation, determine if my credit application is admissible and if I meet the iFinance Canada Inc.
                subscription standards, manage my credit application and/or the loan that could possibly be granted, take, when necessary, recovery measures, comply with laws or regulations and
                prevent, detect and manage possible fraud and other criminal activity. I also hereby authorize any person with whom iFinance Canada Inc. may communicate in this regard to provide,
                transmit and communicate to the latter the information requested in accordance with this consent.
            </p>

            <p>
                2. I confirm that this consent will take effect from this day and will remain valid for the duration of the contract binding me to iFinance Canada Inc.,
                if applicable, including for the exercise of remedies and other means provided for or resulting from this contract. For clarification purposes, iFinance Canada Inc. may
                use this consent to obtain my bank statements throughout the contract binding me to the latter and even after, if this is necessary for the exercise of iFinance Canada Inc.'s rights
                and remedies provided in or resulting from the contract.
            </p>
            <p>
                3. I confirm that I have the legal capacity to give this consent.
            </p>
            <p>
                <b>Communicate with iFinance Canada Inc.'s person in charge of the protection of personal information and
                    respect of privacy.</b>
            </p>
            <p>
                For any questions regarding this consent or iFinance Canada Inc.'s practices concerning the protection of personal information and respect of privacy,
                please contact iFinance Canada Inc.'s person in charge of the protection of personal information and respect of privacy using one of the following :
            </p>
            <ul >
                <li>By e-mail: <a href="mailto:credit@ifinancecanada.com">credit@ifinancecanada.com</a></li>
                <li>By mail: 55 Bloor Street, Box 19645, Toronto, ON, M4W 3T9</li>
                <li>By phone: <a href="tel:1-888-689-9876">1-888-689-9876</a></li>
            </ul>

            <p>
                <b>Withdraw your consent</b>
            </p>

            <p>
                You can withdraw the consent you have given hereunder at any time. To do this,
                you must communicate in writing with the person in charge, by mail or by e-mail, using the contact information
                mentioned above. Please note that this person might ask you to identify yourself. <b><u>However, take notice that withdrawing your consent to the collection,
                    use or disclosure of certain personal information can prevent iFinance Canada Inc. from offering you certain services, including granting you the requested loan or
                    acquiescing to it or that the loan granted to you becomes due and payable.</u></b>
            </p>

            <p>
                <b>Access or modify your personal information</b>
            </p>
            <p>
                You can request access to the personal information collected about you at any time, until these are changed or deleted. To make such a request,
                please communicate in writing with the person in charge, by mail or by e-mail, using the contact information mentioned above.
            </p>

            <p>
                <b>
                    Conservation of your personal information
                </b>
            </p>

            <p >
                Once the transactions covered by this consent have been completed and under the terms of the
                contract binding you to iFinance Canada Inc., your personal information will only be kept insofar as planned and/or required by law.
            </p>
            <br />
        </>
    )
}

export default ConsentEn
type Props = {
    language: string,
    tag: string,
    iframUrl: string
}

function Flinks({ language, tag, iframUrl }: Props) {

    const isDemo = process.env.REACT_APP_USE_DEMO_FLINKS ?? 'false'
    const formatUrl = iframUrl.replace("{0}", isDemo).replace("{1}", language).replace("{2}", tag)
    return (
        <div style={{ backgroundColor: 'white' }}>
            <iframe title="flinksIFrame" height="760" src={formatUrl} />
        </div>
    )
}

export default Flinks
function ConsentFr() {
    return (
        <>
            <h1 >Consentement</h1>
            <p>1. J’autorise iFinance Canada Inc., ou tout fournisseur de services ayant été mandaté par iFinance Canada Inc., à recueillir, vérifier et/ou obtenir tout renseignement personnel me concernant, incluant notamment,
                mais sans s’y limiter, mes relevés bancaires ainsi qu’un spécimen chèque, dans la mesure où ceux-ci sont
                nécessaires à l’analyse, à l’évaluation ou à la gestion de mon dossier ou permettent, le cas échéant, à
                iFinance Canada Inc. d’exercer ses recours et autres moyens prévus au contrat me liant à credit@ifinancecanada.com, le
                cas échéant, et ce, auprès de toute personne ou de tout organisme, dont notamment mais non
                limitativement auprès de mon employeur, du propriétaire des lieux où je réside advenant que je sois
                locataire ainsi qu’auprès de toute agence d’évaluation de crédit, assureur, prêteur hypothécaire, banque à
                charte, caisse populaire, prêteur et/ou toute personne avec qui j’entretiens ou j’ai entretenu des relations
                financières, de les utiliser, de les transmettre, de les conserver et/ou de les communiquer afin de,
                notamment mais non limitativement confirmer mon identité et mon emploi, me contacter, évaluer ma situation
                financière et professionnelle, déterminer si ma demande de crédit est recevable et si je respecte les normes
                de souscription d’iFinance Canada Inc., gérer ma demande de crédit et/ou le prêt qui pourrait éventuellement
                m’être accordé, prendre, lorsque nécessaire des mesures de recouvrement, se conformer aux lois ou aux
                règlements et prévenir, détecter et gérer des possibles fraudes ou autres activités criminelles. J’autorise
                également par la présente toute personne avec qui iFinance Canada Inc. pourrait communiquer à cet égard à
                fournir, transmettre et communiquer à cette dernière les renseignements demandés conformément au présent
                consentement.
            </p>

            <p>
                2. Je confirme que le présent consentement prendra effet à compter de ce jour et demeurera valide pour toute
                la durée du contrat me liant à iFinance Canada Inc., le cas échéant, incluant pour l’exercice des recours ou
                autres moyens prévus au ou découlant de ce contrat. Pour fins de clarification, iFinance Canada Inc. pourra
                utiliser le présent consentement pour obtenir mes relevés bancaires et ce, tout au long du contrat me liant
                à cette dernière et même après, si cela s’avère nécessaire pour l’exercice des droits et recours d’iFinance Canada Inc.
                prévus au ou découlant du contrat.
            </p>
            <p>
                3. Je confirme avoir la capacité légale de donner ce consentement.
            </p>
            <p>
                <b>Communiquer avec le responsable de la protection des renseignements personnels et du respect de la vie
                    privée d’iFinance Canada Inc. </b>
            </p>
            <p>
                Pour toute question relative au présent consentement ou sur les pratiques d’iFinance Canada Inc. en matière
                de protection des renseignements personnels et du respect de la vie privée, veuillez-vous adresser au
                responsable du respect de la vie privée d’iFinance Canada Inc. aux coordonnées suivantes :
            </p>
            <ul >
                <li>Par courriel: <a href='mailto:credit@ifinancecanada.com'>credit@ifinancecanada.com</a></li>
                <li>Par la poste: 55 Bloor Street, Box 19645, Toronto, ON, M4W 3T9</li>
                <li>Par téléphone: <a href="tel:1-888-689-9876">1-888-689-9876</a></li>
            </ul>

            <p>
                <b>Retirer votre consentement</b>
            </p>

            <p>
                Vous pouvez retirer en tout temps le consentement que vous avez donné en vertu des présentes. Pour ce faire,
                vous devez communiquer par écrit avec le responsable, par la poste ou par courriel, aux coordonnées
                mentionnées ci-haut. Veuillez noter que celui-ci peut vous demander de vous identifier. <b><u>Toutefois,
                    prenez avis que le retrait de votre consentement à la collecte, à l’utilisation ou à la divulgation de
                    certains renseignements personnels peut faire en sorte qu’iFinance Canada Inc. ne soit plus en mesure de
                    vous offrir certains services, dont notamment de vous octroyer le prêt demandé ou acquiescer à celui-ci
                    ou que le prêt qui vous a été octroyé devienne dû et exigible.</u></b>
            </p>

            <p>
                <b>Accéder ou modifier vos renseignements personnels</b>
            </p>
            <p>
                Vous pouvez demander à tout moment d’avoir accès aux renseignements personnels recueillis sur vous, ainsi
                qu’à ce que ceux-ci soient modifiés ou supprimés. Pour présenter une telle demande, veuillez communiquer par
                écrit avec le responsable, par la poste ou par courriel, aux coordonnées mentionnées ci-dessus.
            </p>

            <p ><b>Conservation de vos renseignements personnels</b></p>

            <p >
                Une fois que les transactions visées par le présent consentement auront été complétées et aux termes du
                contrat vous liant à iFinance Canada Inc., vos renseignements personnels seront uniquement conservés dans la
                mesure prévue et/ou exigée par la loi.
            </p>
            <br />
        </>
    )
}

export default ConsentFr